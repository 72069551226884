<template>
    <div>
        <CRow>
            <CCol md="12">
                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol md="4">
                                Semua User
                            </CCol>
                            <CCol md="8" class="text-right">
                                <router-link :to="{name: 'AddUser'}" class="btn btn-success btn-sm"><i class="fa fa-plus"></i> Tambah</router-link>
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol sm="12">
                                <CRow>
                                    <CCol sm="12">
                                        <CRow class="my-2 mx-0">
                                            <CCol sm="6" class="form-inline p-0">
                                                <label data-v-1c2db5c2="" class="mr-2">Filter:</label>
                                                <input type="text" class="form-control" id="search-table" placeholder="Search.." v-on:change="searchMaster" v-model="searchData" />
                                            </CCol>
                                            <CCol sm="6" class="p-0 text-right">
                                                Show {{show_data_from}}-{{show_data_to}} From {{total_data}}
                                            </CCol>
                                        </CRow>
                                        <table class="table table-sm table-bordered">
                                            <thead class="bg-dark text-white">
                                                <tr>
                                                    <th>No</th>
                                                    <th v-if="role == 'admin'">NIP</th>
                                                    <th>Nama</th>
                                                    <th>Email</th>
                                                    <th>Jabatan</th>
                                                    <th>Role</th>
                                                    <th>Status</th>
                                                    <th>Aksi</th>
                                                </tr>
                                            </thead>
                                            
                                                <tbody>
                                                    <template>
                                                        <tr v-if="loadingTable">
                                                            <td colspan="7"><i class="fa fa-spin fa-refresh"></i> Loading...</td>
                                                        </tr>
                                                        <tr v-else-if="!loadingTable && reslistuser.length <= 0">
                                                            <td colspan="7"><i class="fa fa-times"></i> Data Tidak Tersedia</td>
                                                        </tr>
                                                        <tr v-for="(listuser, key_user) in reslistuser" :key="key_user" v-else>
                                                            <td>{{key_user+1}}</td>
                                                            <td v-if="role == 'admin'">{{listuser.nip}}</td>
                                                            <td>{{listuser.name}}</td>
                                                            <td>{{listuser.email}}</td>
                                                            <td>{{listuser.jabatan}}</td>
                                                            <td>{{listuser.roles.name}}</td>
                                                            <td>
                                                                <template>
                                                                    <CBadge :color="getBadge(listuser.status)">
                                                                        <div v-if="listuser.status">
                                                                            Enable
                                                                        </div>
                                                                        <div v-else>
                                                                            Disable
                                                                        </div>
                                                                    </CBadge>
                                                                </template>
                                                            </td>
                                                            <td>
                              <template>
                                <button
                                  v-if="listuser.status"
                                  class="btn btn-danger btn-sm"
                                  v-on:click="deleteData(listuser.id)"
                                >
                                  <i class="fa fa-trash"></i> Disable
                                </button>
                                <button
                                  v-else
                                  class="btn btn-warning btn-sm"
                                  v-on:click="restoreData(listuser.id)"
                                >
                                  <i class="fa fa-refresh"></i> Enable
                                </button>
                              </template>
                                                                <router-link :to="{name: 'EditUser', params:{userID: listuser.id}}" class="btn btn-info btn-sm"><i class="fa fa-pencil"></i> Edit</router-link>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                                
                                            
                                        </table>
                                        <nav aria-label="pagination">
                                            <ul class="pagination justify-content-start">
                                                <!---->
                                                <!-- {{ page.active ? 'active' : '' }} -->
                                                <li v-for="(page, key_page) in paging" :key="key_page" v-bind:class="{ 'active': page.active }" class="page-item">
                                                    <div v-on:click="toPage(page.url)" class="page-link c-page-link-number">
                                                        <div v-html="page.label"></div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </nav>
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>
                        <br />
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
    const datauser = [];
    export default {
        name: "AllUser",
        components: {},
        data() {
            return {
                reslistuser: datauser,
                paging: [],
                show_data_to: "",
                show_data_from: "",
                total_data: "",
                searchData: "",
                loadingTable: false,
                role: this.$route.params.role,
            };
        },
        mounted(){
            this.loadingTable = true;
        },
        methods: {
            deleteData(id) {
                // alert(id);
                Swal.fire({
                    title: "Apakah anda yakin?",
                    text: "Anda tidak bisa mengembalikan data yang dihapus!",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: `Hapus`,
                    denyButtonText: `Batal`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        Swal.fire({
                            title: '<i class="fa fa-refresh fa-spin"></i>',
                            text: "Loading...",
                            showConfirmButton: false,
                        });
                        axios
                            .delete(this.apiLink + "api/crud/user", {
                                headers: {
                                    Authorization: "Bearer " + this.access_token,
                                },
                                data: {
                                    id: id,
                                },
                            })
                            .then((response) => {
                                var res_data = response.data;
                                // console.log(res_data);
                                if (res_data.meta.code != "200") {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Oops...",
                                        text: res_data.data.message,
                                    });
                                } else {
                                    let timerInterval;
                                    Swal.fire({
                                        icon: "success",
                                        title: "Berhasil",
                                        text: "Data user berhasil dihapus.",
                                        timer: 2000,
                                        timerProgressBar: true,
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        didOpen: () => {
                                            timerInterval = setInterval(() => {
                                                const content = Swal.getContent();
                                                if (content) {
                                                    const b = content.querySelector("b");
                                                    if (b) {
                                                        b.textContent = Swal.getTimerLeft();
                                                    }
                                                }
                                            }, 100);
                                        },
                                        willClose: () => {
                                            clearInterval(timerInterval);
                                        },
                                    }).then((result) => {
                                        /* Read more about handling dismissals below */
                                        if (result.dismiss === Swal.DismissReason.timer) {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                    } else if (result.isDenied) {
                        Swal.close();
                    }
                });
            },
    restoreData(id) {
      // alert(id);
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Anda akan me-restore data tersebut.",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Hapus`,
        denyButtonText: `Batal`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          axios
            .delete(this.apiLink + "api/crud/user", {
              headers: {
                Authorization: "Bearer " + this.access_token,
              },
              data: {
                id: id,
              },
            })
            .then((response) => {
              var res_data = response.data;
              // console.log(res_data);
              if (res_data.meta.code != "200") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res_data.data.message,
                });
              } else {
                let timerInterval;
                Swal.fire({
                  icon: "success",
                  title: "Berhasil",
                  text: "Data user berhasil dihapus.",
                  timer: 2000,
                  timerProgressBar: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                  didOpen: () => {
                    timerInterval = setInterval(() => {
                      const content = Swal.getContent();
                      if (content) {
                        const b = content.querySelector("b");
                        if (b) {
                          b.textContent = Swal.getTimerLeft();
                        }
                      }
                    }, 100);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                  },
                }).then((result) => {
                  /* Read more about handling dismissals below */
                  if (result.dismiss === Swal.DismissReason.timer) {
                    window.location.reload();
                  }
                });
              }
            });
        } else if (result.isDenied) {
          Swal.close();
        }
      });
    },
            toPage(url) {
                this.loadingTable = true;
                // var numpage = url.replace(this.apiLink + "api/crud/user?page=", "");
                axios
                    .get(url, {
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    })
                    .then((response) => {
                        var res_user = response.data;
                        var res_user_data = res_user.data[0];
                        // console.log(res_user_data);
                        if (res_user.meta.code != "200") {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: res_user.data.message,
                            });
                        } else {
                            this.loadingTable = false;
                            this.reslistuser = res_user_data.data;
                            this.paging = res_user_data.links;
                            this.show_data_from = res_user_data.from;
                            this.show_data_to = res_user_data.to;
                        }
                    });
            },
            color(value) {
                let $color;
                if (value <= 25) {
                    $color = "info";
                } else if (value > 25 && value <= 50) {
                    $color = "success";
                } else if (value > 50 && value <= 75) {
                    $color = "warning";
                } else if (value > 75 && value <= 100) {
                    $color = "danger";
                }
                return $color;
            },
            getBadge(status) {
                if (status) {
                    return "success";
                } else {
                    return "danger";
                }
            },
            searchMaster(){
                this.loadingTable = true;
                axios
                .get(this.apiLink + "api/crud/user", {
                    params: {
                        search: this.searchData,
                        list_as: this.$route.params.role
                    },
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_user = response.data;
                    var res_user_data = res_user.data[0];
                    // console.log(res_user_data);
                    if (res_user.meta.code != "200") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res_user.data.message,
                        });
                    } else {
                        this.loadingTable = false;
                        this.reslistuser = res_user_data.data;
                        this.paging = res_user_data.links;
                        this.show_data_from = res_user_data.from;
                        this.show_data_to = res_user_data.to;
                        this.total_data = res_user_data.total;
                    }
                });
            }
        },
        beforeCreate() {
            axios
                .get(this.apiLink + "api/crud/user", {
                    params:{
                        list_as: this.$route.params.role
                    },
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_user = response.data;
                    var res_user_data = res_user.data[0];
                    // console.log(res_user_data);
                    if (res_user.meta.code != "200") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res_user.data.message,

                        });
                    } else {
                        this.loadingTable = false;
                        this.reslistuser = res_user_data.data;
                        this.paging = res_user_data.links;
                        this.show_data_from = res_user_data.from;
                        this.show_data_to = res_user_data.to;
                        this.total_data = res_user_data.total;
                    }
                });
        },
    };
</script>